// Package imports:
import React, { useMemo, useRef } from "react";
import Bugsnag from "@bugsnag/js";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Input from "../../ui-elements/Input/Formik/Input";
import Link from "../../ui-elements/Link/Link";
import Table from "../../ui-elements/Table/Table";
import Loading from "../../ui-elements/Loading/Loading";
import Tooltip from "../../ui-elements/Tooltip/Tooltip";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import Label from "../../ui-elements/Label/Label";
import SearchResults from "../../ui-elements/SearchResults/SearchResults";
// Service imports:
import { insertDash, isSSN, isSubscriptionValid } from "../../services/utils";
import { useBuyingProcess } from "../../services/buyhook";
import { GET_KELDAN_API_URL } from "../../services/config";
// Type imports:
import { IKeldanApiResponse, Subscription } from "../../types/KeldanTypes";
import { IPepType, Result } from "../../types/PepTypes";
import { IPepArray, IPepData } from "./PepPage";
import { IForeignSearchParams } from "./ForeignSearch";
import { useHistoryRefresh } from "../../contexts/HistoryRefreshContext";

interface IProps {
  isAuthenticated: boolean;
  subscriptions: Subscription[] | null;
  setParamsForForeignSearch: (params: IForeignSearchParams) => void;
}

const relatesToFormer = (date: string | number | null) => {
  return date !== null && new Date(date) < new Date();
};

export const sortEntries = (results: Result[] | null) => {
  if (!results || results.length === 0) {
    return [];
  }
  const arrayToReturn: IPepArray[] = [];
  for (const result of results) {
    const { name, ssn, entries } = result; // Extract name, ssn, and entries from the current Result object
    if (entries === null) return [];

    const primaryData = entries.filter((x) => x.primaryEntry);
    const secondaryData = entries.filter((x) => !x.primaryEntry);
    for (const x of primaryData) {
      arrayToReturn.push({
        name: `${name} (${insertDash(ssn)})`,
        connection: "Frumaðili",
        status: `${x.position} (${x.category})`,
        source: relatesToFormer(x.endDate) ? (
          x.notes
        ) : (
          <Link url={x.source ?? "#"} linkSize="14" target="_blank">
            {x.source}
          </Link>
        ),
      });
    }
    for (const y of secondaryData) {
      arrayToReturn.push({
        name: `${y.relatesToName} (${insertDash(y.relatesToSsn)})`,
        connection: `${y.category}`,
        status: `${y.relatesToPosition} (${y.relatesToCategory})`,
        source: relatesToFormer(y.relatesToEndDate) ? (
          y.relatesToNotes
        ) : (
          <Link url={y.relatesToSource} linkSize="14" target="_blank">
            {y.relatesToSource}
          </Link>
        ),
      });
    }
  }
  return arrayToReturn;
};
function getDobFromSsn(ssn: string | undefined): string | undefined {
  if (!ssn) {
    return undefined;
  }

  const day = ssn.slice(0, 2);
  const month = ssn.slice(2, 4);
  const yearSuffix = ssn.slice(4, 6);
  const centuryIndicator = ssn.slice(-1);

  let yearPrefix;
  if (centuryIndicator === "9") {
    yearPrefix = "19";
  } else if (centuryIndicator === "0") {
    yearPrefix = "20";
  } else {
    throw new Error("Invalid SSN");
  }

  const year = yearPrefix + yearSuffix;

  const dob = new Date(`${year}-${month}-${day}`);

  return dob.toISOString().split("T")[0];
}

function createParams(name: string | undefined, ssn: string | undefined): IForeignSearchParams {
  const parts = (name || "").trim().split(" ");

  let firstName = "";
  let middleName = "";
  let lastName = "";
  const dob = getDobFromSsn(ssn);
  if (parts.length >= 1) {
    firstName = parts[0];
  }

  if (parts.length >= 3) {
    middleName = parts.slice(1, parts.length - 1).join(" ");
    lastName = parts[parts.length - 1];
  } else if (parts.length === 2) {
    lastName = parts[1];
  }

  const params: IForeignSearchParams = {
    firstName,
    middleName,
    lastName,
    dob,
  };

  return params;
}
const DomesticPepSearch: React.FC<IProps> = ({ isAuthenticated, subscriptions, setParamsForForeignSearch }) => {
  const { triggerRefresh } = useHistoryRefresh();
  const [data, setData] =
    React.useState<
      | (IPepData & {
          event: {
            id: number;
            date: string;
          };
        })
      | null
    >();
  const cleanResultsAndForm = () => {
    setData(undefined);
  };
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const displayResultSection = () => {
    if (data === undefined) return <></>;

    return (
      <SearchResults
        title={data ? `Niðurstöður fyrir ${data.name} (${insertDash(data.ssn)})` : ""}
        show
        closeResults={() => cleanResultsAndForm()}
        ref={searchResultsRef}
        component={
          data === null ? (
            <DisplayBox>
              <Loading />
            </DisplayBox>
          ) : (
            <>
              <DisplayBox
                titleComponent={
                  <>
                    Stjórnmálaleg tengsl:
                    <Label labelType={data.connected ? "yes" : "no"} size="lg" text={data.connected ? "Já" : "Nei"} />
                  </>
                }
                asideComponent={
                  <Link targetBlank linkSize="15" url={`/Pdf/Valistar/${data.event.id}`} icon="pdf">
                    Sækja skýrslu
                  </Link>
                }
              >
                <Table
                  tableSize="lg"
                  data={data.connected ? data.entries : []}
                  columns={[
                    {
                      title: "Aðili",
                      renderCell: ({ name }) => name,
                      textAlign: "left",
                    },
                    {
                      title: "Tengsl",
                      renderCell: ({ connection }) => connection,
                      textAlign: "left",
                    },
                    {
                      title: "Staða",
                      renderCell: ({ status }) => status,
                      textAlign: "left",
                    },
                    {
                      title: "Heimild",
                      renderCell: ({ source }) => source,
                    },
                  ]}
                />
                {!data.connected && <p className="noResultText italic-text">Engin stjórnmálaleg tengsl</p>}
              </DisplayBox>
              <Link
                style={{ marginTop: "20px" }}
                url={`/Valistar/Leit#Althjodlegt-Einstaklingar`}
                icon="forward"
                onClick={() => setParamsForForeignSearch(createParams(data.name, data.ssn))}
              >
                Leita í alþjóðlegum válistum
              </Link>
            </>
          )
        }
      />
    );
  };
  const getPurchaseOption: "Leita" | "Kaupa" = useMemo(() => {
    //will also be disabled
    if (subscriptions === null) return "Leita";
    for (const subscription of subscriptions) {
      const now = new Date();
      const expires = new Date(subscription.Expires);
      if (subscription.Active && subscription.ProductId === 20 && expires > now) {
        const instance = subscription.ProductInstances?.find((inst) => inst.ProductId === 20);
        if (instance) {
          return instance.Discount === 1 ? "Leita" : "Kaupa";
        }
      }
    }
    return "Leita"; // Default if not found
  }, [subscriptions]);

  const { tryPurchaseItem, isItemLoading, setToastError, resetState, ModalsAndToasts } = useBuyingProcess(
    async (ssn: string, modalReference: string) => {
      try {
        const requestQuery = new URLSearchParams({
          id: ssn,
          reference: modalReference,
        });
        //indicate loading
        setData(null);
        const url = `${GET_KELDAN_API_URL()}/Valistar/Skyrsla-Kaupa`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: requestQuery,
        });
        if (response.ok) {
          const responseBody: IKeldanApiResponse<
            IPepType & {
              event: {
                id: number;
                date: string;
              };
            }
          > = await response.json();
          if (
            responseBody.success &&
            responseBody.result &&
            responseBody.result.results &&
            responseBody.result.results[0]
          ) {
            //refresh the history  buttton
            triggerRefresh();
            // Sort the entries and set the data
            setData({
              name: responseBody.result.results[0].name,
              ssn: responseBody.result.results[0].ssn,
              connected: responseBody.result.results[0].connected,
              entries: sortEntries(responseBody.result.results ?? null),
              event: {
                id: responseBody.result.report.balls,
                date: responseBody.result.report.date,
              },
            });
            searchResultsRef.current?.scrollIntoView();
          } else {
            setToastError("bad request");
          }
        } else {
          resetState("response not ok");
        }
      } catch (e) {
        if (e instanceof Error) Bugsnag.notify(e);
        resetState("network error");
      }
    },
    "PEP lista - stjórnmálaleg tengsl"
  );                                                                

  const displayForm = (type: "disabled" | "enabled") => (
    <Formik
      initialValues={{
        ssn: "",
        tilvisun: "",
      }}
      validationSchema={Yup.object({
        ssn: Yup.string()
          .test("ssn-test", "Vinsamlegast sláðu inn gilda kennitölu", (value) => isSSN(value))
          .required("Leit má ekki vera tóm"),
      })}
      onSubmit={(values) => {
        const trimmed = values.ssn.trim().replaceAll("-", "");
        if (isSSN(trimmed)) {
          tryPurchaseItem(trimmed, "confirm-modal-with-reference", values.tilvisun);
        }
      }}
      component={(props: { values: ISubFormValues }) => (
        <FormSubComponent
          disabled={type === "disabled"}
          values={props.values}
          loading={isItemLoading(props.values.ssn)}
          buttonString={getPurchaseOption}
        />
      )}
    />
  );

  const isDisabled = useMemo(() => {
    return !isSubscriptionValid(subscriptions, isAuthenticated, 20);
  }, [isAuthenticated, subscriptions]);

  return (
    <>
      <div className="main KCL_pep-search shell">
        <div className="main__inner-fluid ">
          <div className="descriptionWrapper">
            <p className="summary-paragraph">
              Hér geta tilkynningarskyldir aðilar flett upp einstaklingum eftir kennitölu.
            </p>
            <p className="paragraph">
              Á listanum eru einstaklingar sem eru eða hafa verið háttsettir í opinberri þjónustu ásamt nánustu
              fjölskyldu og samstarfsmönnum á síðustu 18 mánuðum.&nbsp;
              <Link linkSize="18" url="/Skilmalar#peplisti">
                Sjá skilmála.
              </Link>
            </p>
          </div>
          <div>
            {!isDisabled ? (
              displayForm("enabled")
            ) : (
              <Tooltip
                tooltip={<span>Eingöngu fyrir tilkynningarskylda aðila í áskrift.</span>}
                text={displayForm("disabled")}
              />
            )}
          </div>
          {isDisabled ? (
            <div style={{ marginTop: "30px" }}>
              <p className="paragraph">
                Ertu tilkynningarskyldur aðili en ekki með aðgang?{" "}
                <Link url="/Hafa-Samband/PEP" linkSize="18">
                  Hafðu samband.
                </Link>
              </p>
            </div>
          ) : (
            <div style={{ marginTop: "50px", paddingBottom: "100px" }}>
              <Link url="/Min-Kelda/Keyptar-Skyrslur/PEP" linkSize="16" icon="forward">
                Sjá allar uppflettingar
              </Link>
            </div>
          )}
        </div>
        <ModalsAndToasts />
      </div>
      {displayResultSection()}
    </>
  );
};

export default DomesticPepSearch;

interface ISubFormValues {
  ssn: string;
  tilvisun: string;
}

interface ISubFormProps {
  values: ISubFormValues;
  loading: boolean;
  disabled: boolean;
  buttonString: string;
}

const FormSubComponent: React.FC<ISubFormProps> = ({ values, loading, disabled, buttonString }) => (
  <Form>
    <div className="form__body">
      <div className="form__section">
        <div className="form__row">
          <div className="form__col">
            <Input disabled={disabled} label="Kennitala" name="ssn" id="ssn" value={values.ssn} />
          </div>
          <div className="form__col">
            <Input
              disabled={disabled}
              label="Tilvísun á reikning"
              name="tilvisun"
              id="tilvisun"
              value={values.tilvisun}
            />
          </div>
          <div className="form__col actions">
            <div className="form__actions">
              <Button
                buttonType={buttonString === "Kaupa" ? "buy" : "primary"}
                showLoader={loading}
                disabled={disabled}
                size="lg"
              >
                {buttonString}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
);
