// Package imports:
import { format, lastDayOfMonth, subMonths } from "date-fns";
// Type imports:
import { ICompanyValuation } from "../types/CompanyTypes";
import { Subscription } from "../types/KeldanTypes";

export const formatNumber = (
  num: number | null | undefined,
  fallBackSymbol: string | null = "-",
  fractionDigits: number = 2,
  suffix: string | null = ""
) => {
  if (num === null || num === undefined || isNaN(num)) {
    return fallBackSymbol;
  }

  const formattedNumber = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);
  return formattedNumber + (suffix ?? "");
};

export function convertToPercentage(num: number | null, displayPercentageSign: boolean, fractionDigits: number = 2) {
  if (num === null) {
    return "-";
  }
  num = num * 100;

  if (displayPercentageSign) {
    return formatNumber(num, null, fractionDigits, "%");
  } else {
    return formatNumber(num, null, fractionDigits, "");
  }
}

export function displayQuantityShorthand(quantity?: number | null, fractionDigits?: number): string {
  if (quantity === undefined || quantity === null) {
    return "-";
  }
  if (quantity < 0) {
    return `-${displayQuantityShorthand(-quantity)}`;
  }
  //check if its 1b or more
  if (quantity >= 1000000000) {
    return (quantity / 1000000000).toFixed(fractionDigits ?? 1) + "ma";
  } else if (quantity >= 1000000) {
    return (quantity / 1000000).toFixed(fractionDigits ?? 0) + "m";
  } else if (quantity >= 1000) {
    return (quantity / 1000).toFixed(fractionDigits ?? 0) + "þ";
  }
  return "0";
}

export function quantityShorthandSplit(
  quantity: number | null,
  fractionDigits?: number
): { number: string | null; unit: string | null } {
  if (quantity === null) {
    return { number: null, unit: null };
  }
  if (Math.abs(quantity) >= 1000000) {
    return {
      number: formatNumber(quantity / 1000000, null, fractionDigits ?? 1),
      unit: " m",
    };
  } else if (Math.abs(quantity) >= 1000) {
    return {
      number: formatNumber(quantity / 1000, null, fractionDigits ?? 1),
      unit: " þ",
    };
  }
  return {
    number: formatNumber(quantity, null, 0),
    unit: null,
  };
}

export function displayPrice(price?: number): string {
  if (price === undefined) {
    return "-";
  }
  if (price < 0) {
    return `-${displayPrice(-price)}`;
  }
  //check if its 1b or more
  if (price >= 1000000000) {
    return `${formatNumber(price / 1000000000, null, 3)} ma.kr.`;
  } else if (price >= 1000000) {
    return `${formatNumber(price / 1000000, null, 2)} m.kr.`;
  } else if (price >= 1000) {
    return `${formatNumber(price / 1000, null, 2)} þ.kr.`;
  }
  return "0";
}

export function calculateDelta(num: number) {
  num = num * 10000;
  if (Math.abs(num) === 0) {
    return "-";
  }

  if (0 <= Math.abs(num) && Math.abs(num) <= 0.5) {
    if (num < 0) return "-" + Math.round(num);
  } else {
    return +Math.round(num);
  }
  return Math.round(num);
}

//Returns a string of the format YYYY-MM-DD
export function getAPItoday(date: Date = new Date()) {
  return format(date, "yyyy-MM-dd");
}

export const getLastDayOfLastMonth = () => {
  const date = new Date();
  const lastDayOfLastMonth = lastDayOfMonth(subMonths(date, 1));
  const dateStringFormat = format(lastDayOfLastMonth, "yyyy-MM-dd");
  return dateStringFormat;
};

//returns a string with prefered format
//if no format is provided it defaults to null
export function getTableDate(
  tableDate: string | Date | null,
  format: "HH:MM" | "HH:MM:SS" | "DD/MM" | "DD/MM/YY" | "DD/MM/YYYY" | "DD/MM/YYYY HH:MM" | "DD/MM/YYYY HH:MM:SS",
  separator: "." | "/" = "/"
): string {
  if (tableDate === null) return "-";
  const date = typeof tableDate === "string" ? new Date(tableDate.replace(/ /g, "T")) : tableDate;
  if (!(date instanceof Date) || isNaN(date.getTime())) return "-";

  if (date.getFullYear() < 1800) return "-";

  switch (format) {
    case "HH:MM":
      if (isDateToday(date)) {
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
      }
      return `${date.getDate().toString().padStart(2, "0")}${separator}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${separator}${date.getFullYear()}`;
    case "HH:MM:SS":
      if (isDateToday(date)) {
        return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
      }
      return `${date.getDate().toString().padStart(2, "0")}${separator}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${separator}${date.getFullYear()}`;
    case "DD/MM":
      return `${date.getDate().toString().padStart(2, "0")}${separator}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${separator === "." ? separator : ""}`;
    case "DD/MM/YY":
      return `${date.getDate().toString().padStart(2, "0")}${separator}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${separator}${(date.getFullYear() % 100).toString().padStart(2, "0")}`;
    case "DD/MM/YYYY":
      return `${date.getDate().toString().padStart(2, "0")}${separator}${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${separator}${date.getFullYear()}`;
    case "DD/MM/YYYY HH:MM":
      return (
        date.getDate().toString().padStart(2, "0") +
        separator +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        separator +
        date.getFullYear() +
        " " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    case "DD/MM/YYYY HH:MM:SS":
      return (
        date.getDate().toString().padStart(2, "0") +
        separator +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        separator +
        date.getFullYear() +
        " " +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0") +
        ":" +
        date.getSeconds().toString().padStart(2, "0")
      );
    default:
      return "-";
  }
}

export function getFullDateAndTime(date: Date): string {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "NAN";
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleDateString("is-IS", options);
}

export function getFullMonthAndYear(date: Date) {
  const MONTHS = [
    "Janúar",
    "Febrúar",
    "Mars",
    "Apríl",
    "Maí",
    "Júní",
    "Júlí",
    "Ágúst",
    "September",
    "Október",
    "Nóvember",
    "Desember",
  ];
  return MONTHS[date.getMonth()] + " " + date.getFullYear();
}

export function getTimeFromDate(date: string | null, format: "HH:MM" | "HH:MM:SS" = "HH:MM:SS", separator: ":" = ":") {
  if (date === null) return null;
  const newDate = new Date(date.replace(/ /g, "T"));

  if (format === "HH:MM") {
    return `${newDate.getHours().toString().padStart(2, "0")}${separator}${newDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }
  return `${newDate.getHours().toString().padStart(2, "0")}${separator}${newDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}${separator}${newDate.getSeconds().toString().padStart(2, "0")}`;
}

export const changeIssuerName = (name: string | null) => {
  const issuerToAvatarCode: {
    [T in string]: string;
  } = {
    Íslandssjóðir: "IS",
    "AKTA Sjóðir": "AKTA",
    "Kvika eignastýring": "KVIKAE",
    "Íslensk Verðbréf": "IV",
    Landsbréf: "LANDSBREF",
    Stefnir: "STEFNIR",
    "Frjálsi lífeyrissjóðurinn": "FRJALSI",
    "SIV eignastýring": "SIV",
    "A/F Rekstraraðili hf.": "AF",
    // Sereignarsjodir:
    "Íslenski lífeyrissjóðurinn": "LAIS",
    Íslandsbanki: "ISB",
  };
  return issuerToAvatarCode[name ?? ""] ?? name;
};

export const getLogoSymbol = (s: string) => {
  if (s === "ICEAIRW180222" || s === "ICEAIRW130821" || s === "ICEAIRW120822") return "ICEAIR";
  if (s === "LEQ") return "LANDSBREF";
  if (s === "ARION SDB") return "ARION";
  return s;
};

export function isDateToday(date: Date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function getNewsDateOrTime(date: Date) {
  const now = new Date();
  const isCurrentYear = date.getFullYear() === now.getFullYear();
  // If today, use time
  if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && isCurrentYear) {
    return (
      <strong>
        {date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")}
      </strong>
    );
  }
  // If in past, use date
  else if (isCurrentYear) {
    return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2, "0") + ".";
  } else {
    return (
      date.getDate().toString().padStart(2, "0") +
      "." +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "." +
      date.getFullYear().toString().slice(2)
    );
  }
}

export const isElementSubChild = (allegedChild: Element, allegedParent: Element) => {
  const rootElement = document.documentElement;
  let currentElement: Element | null = allegedChild;
  while (currentElement !== null && currentElement !== rootElement) {
    if (currentElement === allegedParent) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }
  return false;
};

export const isCompany = (ssn?: string | null) => {
  if (!ssn) return false;
  return ["4", "5", "6", "7"].includes(ssn.charAt(0)) && isSSN(ssn);
};

export const isPerson = (ssn?: string | null) => {
  if (!ssn) return false;
  return ["0", "1", "2", "3"].includes(ssn.charAt(0)) && isSSN(ssn);
};

export const isSSN = (ssn?: string | null, allowEmpty?: boolean) => {
  if (!ssn) {
    return allowEmpty ?? false;
  }
  if (ssn.length === 11 && ssn[6] === "-") {
    ssn = ssn.slice(0, 6) + ssn.slice(7, 11);
  }
  if (ssn.match(/^\d{9}[890]$/) === null) {
    return false;
  }
  if (ssn.slice(0, 2) === "00" || ssn.slice(2, 4) === "00") return false;
  const checker = parseInt(ssn[8]);
  let sum = 0;
  const multipliers = [3, 2, 7, 6, 5, 4, 3, 2];
  for (let i = 0; i < multipliers.length; i++) {
    sum += parseInt(ssn[i]) * multipliers[i];
  }
  let mod = sum % 11;
  if (mod > 0) mod = 11 - mod;
  return checker === mod;
};

export const stripSSN = (ssn?: string | null) => {
  if (ssn && ssn.length === 11 && ssn[6] === "-") {
    return ssn.slice(0, 6) + ssn.slice(7, 11);
  }
  return ssn;
};

export const insertDash = (ssn?: string | null) => {
  if (ssn === null || ssn === undefined) return "-";
  if (ssn.length === 10) return ssn.substring(0, 6) + "-" + ssn.substring(6);
  else return ssn;
};

export function gtmPush(event: string, category: string, action: string, label: string = "keldan_vefur") {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push({
    event: event,
    category: category,
    action: action,
    label: label,
  });
}

export function gtmPushNewsClick(
  newsTitle: string,
  action: string,
  newsSource: string,
  newsId: string,
  publishDate: string
) {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push({
    event: "NewsItemClick",
    category: newsTitle,
    action: action,
    label: "keldan_vefur",
    newsSource: newsSource,
    newsId: newsId,
    newsPublishDate: publishDate,
  });
}

export const getSearchTermsFromSearch = (search: string | null) => {
  if (search === null) return [];
  return search.split(" ").filter((s) => s.length > 0);
};

export const getEventUrl = (
  searchTerm: string | null,
  typeName: string | null,
  eventId: number | null,
  fileId?: string | null
) => {
  const searchTerms = searchTerm?.split(" ") ?? [];

  const switchTypeName = typeName?.replace(/\s$/, "").replace(/\s\s/, " ") ?? "";
  switch (switchTypeName) {
    case "Ársreikningar":
      return `/Pdf/Arsreikningar-Saekja?fileId=${fileId}&ssn=${searchTerms[0]}`;
    case "Samþykktir":
    case "Aukatilkynningar":
    case "Stofngögn":
    case "Samrunagögn":
    case "Hætt við samruna":
      return `/Pdf/Fyrirtaekjaskra?eventId=${eventId}&ssn=${searchTerms[0]}`;
    case "Hlutafélög":
    case "Hlutafélög með heimilisföngum":
    case "Hlutafélög með raunverulegum eigendum":
    case "Hlutafélög með eigendum og heimilisf.":
      return "/Fyrirtaeki/Hlutafelagaskra-Skoda/" + searchTerms[searchTerms.length - 1] + "?eventId=" + eventId;
    case "Fasteignir":
    case "Fasteignir Veðbönd":
      return "/Fasteignir/Fasteignaskra-Skoda/" + eventId;
    case "Fasteignir Eignasaga":
    case "Fasteignir Eignastaða":
      if (isPerson(searchTerms[0])) {
        return "/Einstaklingar/Fasteignir-Skoda/" + eventId;
      }
      return "/Fyrirtaeki/Fasteignir-Skoda/" + stripSSN(searchTerms[0]) + "?eventId=" + eventId;
    case "Fasteignir Verðvísir":
      return "/Fasteignir/Gangverd-Skoda/" + eventId;
    case "Fasteignir Yfirlitsmynd":
      return "/Fasteignir/Yfirlitsmynd-Skoda/" + eventId;
    case "Þinglýst skjöl Skjalalisti":
      return `/Fasteignir/Skjalalisti-Skoda/${eventId}?fastanr=${searchTerms[0]}`;
    case "Þinglýst skjöl":
      return `/Pdf/Thinglyst-Skjal-Skoda/${eventId}`;
    case "Ökutæki":
    case "Ökutæki Veðbönd":
      return "/Okutaeki/Yfirlit/" + eventId;
    case "Ökutæki Ferilskrá":
      return "/Pdf/Okutaeki-Ferilskra/" + eventId;
    case "Ökutæki Kennitala":
      if (isPerson(searchTerms[0])) {
        return "/Einstaklingar/Okutaeki-Skoda/" + eventId;
      }
      return "/Fyrirtaeki/Okutaeki-Skoda/" + stripSSN(searchTerms[0]) + "?eventId=" + eventId;
    case "Þjóðskrá":
      return "/Einstaklingar/Yfirlit/" + eventId;
    case "Aðild":
      return "/Einstaklingar/Hlutafelagathatttaka-Skoda/" + eventId;
    case "Lögbirtingar":
      return "/Logbirtingar/Skyrsla-Skoda/?eventId=" + eventId;
    case "Vinnuvélar":
      return "/Okutaeki/Vinnuvelaskra-Skoda/" + eventId;
    case "Vinnuvélar Kennitala":
      return "/Fyrirtaeki/Vinnuvelar-Skoda/" + stripSSN(searchTerm) + "?eventId=" + eventId;
    case "Listar":
      return "/Listar/Almennt-Skoda/" + searchTerm?.trim() + "?eventId=" + eventId;
    case "Samanburður skýrsla":
      return "/Fyrirtaeki/Samanburdur-Skoda/" + searchTerms[0] + "?eventId=" + eventId;
    case "Reikningar innslegnir":
      return (
        "/Fyrirtaeki/Innslegnir-Arsreikningar-Skoda/" + searchTerms[searchTerms.length - 1] + "?eventId=" + eventId
      );
    case "PEP":
    case "PEP kall stök kennitala":
      return "/Valistar/Skyrsla-Skoda/" + eventId;
    case "PEP erlendir einstaklingar fyrir erlent":
      return "/Valistar/Erlend-Skyrsla-Skoda/" + eventId;
    case "Alþjóðlegir válistar - einstaklingar":
      return "/Valistar/Erlend-Skyrsla-Skoda/" + eventId;
    case "Alþjóðlegir válistar - lögaðilar":
      return "/Valistar/Erlend-Skyrsla-Logadilar-Skoda/" + eventId;

    case "PEP erlendir einstaklingar":
      return "/Valistar/Erlend-Skyrsla-Skoda/" + eventId;
    case "PEP erlend fyrirtæki fyrir erlent fyrirtæki":
      return "/Valistar/Erlend-Skyrsla-Logadilar-Skoda/" + eventId;
    default:
      return "/Min-Kelda/Keyptar-Skyrslur";
  }
};

export const reportTypeNameChanger = (name: string | null | undefined) => {
  if (name === "PEP erlendir einstaklingar") return "Alþjóðlegir válistar - einstaklingar";
  else if (name === "PEP erlendir einstaklingar fyrir erlent") return "Alþjóðlegir válistar - einstaklingar";
  else if (name === "PEP erlend fyrirtæki fyrir erlent fyrirtæki") return "Alþjóðlegir válistar - fyrirtæki";
  return name;
};

// ICELANDIC LETTER SORTING FUNCTION

// Good to know these charcodes for the corrections.
// a: 97
// e: 101
// i: 105
// o: 111
// u: 117
// y: 121
// z: 122
const CHAR_CODE_CORRECTION: {
  [T in number]: number | undefined;
} = {
  225: 97.5, // á => milli a & b
  240: 100.5, // ð => milli d & e
  233: 101.5, // é => milli e & f
  237: 105.5, // í => milli i & j
  243: 111.5, // ó => milli o & p
  250: 117.5, // ú => milli u & v
  253: 121.5, // ý => milli y & z
  254: 122.2, // þ => aðeins meira en z
  230: 122.4, // æ => aðeins meira en þ
  246: 122.6, // ö => aðeins meira en æ
};

export const sortIcelandic = (a: string | null, b: string | null): number => {
  if (a === null && b === null) return 0;
  if (a === null) return -1;
  if (b === null) return 1;
  const lowercaseA = a.toLowerCase();
  const lowercaseB = b.toLowerCase();
  const charCodeA = lowercaseA.charCodeAt(0);
  const charCodeB = lowercaseB.charCodeAt(0);
  const correctedCharCodeA = CHAR_CODE_CORRECTION[charCodeA] ?? charCodeA;
  const correctedCharCodeB = CHAR_CODE_CORRECTION[charCodeB] ?? charCodeB;
  if (correctedCharCodeA === correctedCharCodeB) return sortIcelandic(a.slice(1) || null, b.slice(1) || null);
  return correctedCharCodeA - correctedCharCodeB;
};

export const getNumberOfPages = (totalCountOfItems: number, itemsPerPage: number) =>
  Math.ceil(totalCountOfItems / itemsPerPage);

export const updateDoubleMapValue = <T extends unknown>(
  doubleMap: {
    [key1 in string]?: {
      [key2 in string]?: T;
    };
  },
  key1: string,
  key2: string,
  newData: T
) => {
  // Copy Double Map.
  const doubleMapCopy = { ...doubleMap };
  // Set if empty.
  if (doubleMapCopy?.[key1] === undefined) {
    doubleMapCopy[key1] = {};
  }
  // Copy Single map.
  const nestedMapCopy = { ...doubleMapCopy[key1] };
  // Set data.
  nestedMapCopy[key2] = newData;
  // Put the copies back.
  doubleMapCopy[key1] = nestedMapCopy;
  // Return copy.
  return doubleMapCopy;
};

export const icelandifyString = (s: string) => {
  switch (s) {
    case "Atvinnuhusnaedi":
      return "Atvinnuhúsnæði";
    case "Fjolbyli":
      return "Fjölbýli";
    case "Serbyli":
      return "Sérbýli";
    case "Sumarhus":
      return "Sumarhús";
    case "Bilskur/skur":
      return "Bílskúr/skúr";
    default:
      return s;
  }
};

export const replaceIcelandicLetters = (s: string) => {
  return (
    s
      //Lowercase
      .replace(/á/g, "a")
      .replace(/ð/g, "d")
      .replace(/é/g, "e")
      .replace(/í/g, "i")
      .replace(/ó/g, "o")
      .replace(/ú/g, "u")
      .replace(/ý/g, "y")
      .replace(/þ/g, "th")
      .replace(/æ/g, "ae")
      .replace(/ö/g, "o")
      //Upper case
      .replace(/Á/g, "A")
      .replace(/Ð/g, "D")
      .replace(/É/g, "E")
      .replace(/Í/g, "I")
      .replace(/Ó/g, "O")
      .replace(/Ú/g, "U")
      .replace(/Ý/g, "Y")
      .replace(/Þ/g, "Th")
      .replace(/Æ/g, "Ae")
      .replace(/Ö/g, "O")
  );
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const idGenerator = (s: string) => {
  return replaceIcelandicLetters(s.toLocaleLowerCase()).replace(/ /g, "_");
};

export const getRandomInt = (max?: number, min: number = 0) => {
  const crypto = window.crypto || (window as any).msCrypto;
  const array = new Uint32Array(1);
  const random = crypto.getRandomValues(array)[0];
  if (max === undefined) return random;
  return min + (random % (max - min));
};

const MIN_PWD_LENGTH = 12;
const MAX_PWD_LENGTH = 16;
const PWD_ALPHABET_UPPER = "AÁBCDEÉFGHIÍJKLMNOÓPQRSTUÚVWZÞÆÖ";
const PWD_ALPHABET_LOWER = "aábcdeéfghiíjklmnoópqrstuúvwzþæö";
const PWD_ALPHABET_NUMBERS = "1234567890";
let PWD_ALPHABET = [PWD_ALPHABET_UPPER, PWD_ALPHABET_LOWER, PWD_ALPHABET_NUMBERS];
export const generatePassword = () => {
  let result = "";
  // Choose length of password.
  const length = getRandomInt(MAX_PWD_LENGTH, MIN_PWD_LENGTH);
  // Make sure we have at least one character of each type.
  const baseChars: string[] = [];
  for (const element of PWD_ALPHABET) {
    const nextChar = element[getRandomInt(element.length)];
    baseChars.push(nextChar);
  }
  // Fill the rest of the password with random characters
  for (let i = 3; i < length; i++) {
    const charset = PWD_ALPHABET[getRandomInt(PWD_ALPHABET.length)];
    const nextChar = charset[getRandomInt(charset.length)];
    result = result.concat(nextChar);
  }
  // Add initial characters to random positions.
  for (const element of baseChars) {
    const nextChar = element;
    const index = getRandomInt(result.length);
    result = result.slice(0, index) + nextChar + result.slice(index);
  }
  return result;
};

export const displayAddress = (
  address: string | undefined,
  zip: number | string | undefined,
  post: string | undefined
) => {
  if (address === undefined || address === null) address = "-";
  if (zip === undefined || zip === null) zip = "-";
  if (post === undefined || post === null) post = "-";
  if (address === "-" && zip === "-" && post === "-") return "-";
  return address + ", " + zip + " " + post;
};

export function getCurrencySymbol(currencyCode: string | null): string {
  switch (currencyCode) {
    case "ISK":
      return "kr";
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CHF":
      return "Fr";
    case "DKK":
      return "kr";
    case "SEK":
      return "kr";
    case "NOK":
      return "kr";
    case "CAD":
      return "C$";
    case "PLN":
      return "zł";
    case null:
      return "";
    default:
      return currencyCode; // Default to empty string if currency code is not recognized
  }
}

const NO_VALUATION_INDUSTRIES = [105, 106, 111, 132, 133, 148, 149, 150, 152, 166, 173, 199, 200];

export const showValuation = (data: ICompanyValuation) => {
  if (data.error?.includes("free_cashflow_less_than_zero") || (data.free_cashflow !== null && data.free_cashflow < 0))
    return "negative-fcf";
  if (data.error) return "no";
  if (data.investment !== null && data.depreciation !== null && data.investment / data.depreciation >= 5) return "no";
  if (data.industry_id !== null && NO_VALUATION_INDUSTRIES.includes(data.industry_id)) return "no";
  if (data.equity_valuation !== null && data.ebit !== null && data.equity_valuation / data.ebit > 25) return "no";
  if (data.change_in_nwc !== null && data.ebit !== null && data.change_in_nwc > data.ebit) return "no";
  if (data.income !== null && data.income < 10000000) return "no";
  if (data.wacc !== null && data.wacc > 0.2) return "no";
  return "show";
};

export const SUBSCRIPTION_PRICE = 5490;
export const getSubscriptionPrice = (withSuffix: boolean = false) => {
  return formatNumber(SUBSCRIPTION_PRICE, null, 0, withSuffix ? " kr." : "");
};

export const mapSectionToNav: {
  [T in string]: "market" | "search" | "keldan" | "mykelda" | undefined;
} = {
  market: "market",
  markadir: "market",
  news: "market",
  frettir: "market",
  dagatal: "market",
  company: "search",
  fyrirtaeki: "search",
  individual: "search",
  einstaklingar: "search",
  legal: "search",
  logbirtingar: "search",
  pep: "search",
  property: "search",
  fasteignir: "search",
  vehicle: "search",
  okutaeki: "search",
  home: "keldan",
  leit: "keldan",
  skilmalar: "keldan",
  "um-okkur": "keldan",
  verdskra: "keldan",
  auglysingar: "keldan",
  vefthjonusta: "keldan",
  areidanleikakannanir: "keldan",
  "um-askrift": "keldan",
  askrift: "keldan",
  contact: "keldan",
  "hafa-samband": "keldan",
  keldanmarkets: "keldan",
  "keldan-markadir": "keldan",
  tilkynningar: "keldan",
  lists: "keldan",
  listar: "keldan",
  "sersnidid-verdmat": "keldan",
  mykelda: "mykelda",
  "min-kelda": "mykelda",
};

export const newsTypeMap: {
  [T in string]: string;
} = {
  news: "Frétt",
  press_release: "Fréttatilkynning",
  exchange_notice: "Kauphallartilkynning",
  podcast: "Hlaðvarpsþáttur",
  article: "Grein",
};

/**
 * Checks if user has an active subscription for a specific product ID that hasn't expired
 * @param subscriptions Array of user subscriptions
 * @param isAuthenticated Whether the user is authenticated
 * @param productId Product ID to check for
 * @returns boolean indicating if the subscription is valid
 */
export const isSubscriptionValid = (
  subscriptions: Subscription[] | null,
  isAuthenticated: boolean,
  productId: number
): boolean => {
  if (!subscriptions || !isAuthenticated) return false;
  
  const now = new Date();
  
  return subscriptions.some(sub => 
    sub.ProductId === productId && 
    sub.Active && 
    new Date(sub.Expires) > now
  );
};