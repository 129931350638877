// Package imports:
import React, { useMemo, useRef, useState } from "react";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Loading from "../../ui-elements/Loading/Loading";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import Table from "../../ui-elements/Table/Table";
import SearchResults from "../../ui-elements/SearchResults/SearchResults";
import ForeignPepSearchPersonForm from "./ForeignSearchPersonForm";
import ForeignPepModal from "./ForeignPepModal";
import { formatCategories } from "./PepPage";
import { useHistoryRefresh } from "../../contexts/HistoryRefreshContext";
// Type imports:
import { Subscription } from "../../types/KeldanTypes";
import { ForeignPep, PepResult } from "../../types/PepTypes";
import { isSubscriptionValid } from "../../services/utils";

interface IProps {
  isAuthenticated: boolean;
  subscriptions: Subscription[] | null;
  searchParams: IForeignSearchParams;
}

export interface IForeignSearchParams {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
}
const ForeignPepSearch: React.FC<IProps> = ({ isAuthenticated, subscriptions, searchParams }) => {
  // Get triggerRefresh function from context
  const { triggerRefresh } = useHistoryRefresh();

  //modals
  const [personShowModal, setPersonShowModal] = useState(false);
  const [personModalData, setPersonModalData] = useState<PepResult | undefined>(undefined);

  const [personData, setPersonData] = useState<ForeignPep | null | undefined>(undefined);

  const searchResultsRef = useRef<HTMLDivElement>(null);
  const cleanResultsAndForm = () => {
    setPersonData(undefined);
  };
  function capitalize(str: string | undefined): string {
    if (str === undefined) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const displayResultSection = () => {
    if (personData === undefined) return <></>;
    return (
      <SearchResults
        title={
          personData
            ? `Niðurstöður fyrir ${capitalize(personData.request?.firstName)} ${capitalize(
                personData.request?.lastName
              )}`
            : ""
        }
        show
        closeResults={() => cleanResultsAndForm()}
        ref={searchResultsRef}
        component={
          personData === null ? (
            <DisplayBox>
              <Loading />
            </DisplayBox>
          ) : (
            <DisplayBox
              asideComponent={
                <Link
                  targetBlank
                  linkSize="15"
                  url={`/Pdf/Althjodlegir-valistar-einstaklingar/${personData.reportID}`}
                  icon="pdf"
                >
                  Sækja skýrslu
                </Link>
              }
            >
              {tableComponent}
            </DisplayBox>
          )
        }
      />
    );
  };

  const tableComponent = useMemo(() => {
    return (
      <Table
        tableSize="lg"
        data={personData?.match ?? []}
        showEmptyTableText
        columns={[
          {
            title: "Nafn",
            renderCell: ({ fullName }) => fullName,
            textAlign: "left",
          },
          {
            title: "Fæðingardagur",
            renderCell: ({ dateOfBirth }) => dateOfBirth,
            textAlign: "left",
          },
          {
            title: "Flokkur/ar",
            renderCell: ({ categories }) => formatCategories(categories),
            textAlign: "left",
          },
          {
            title: "Ítarefni",
            renderCell: (item) => (
              <Button
                onClick={() => {
                  setPersonModalData(item);
                  setPersonShowModal(true);
                }}
                buttonType="secondary"
                size="sm"
              >
                Skoða
              </Button>
            ),
          },
        ]}
      />
    );
  }, [personData]);

  const handleSearchResults = (data: ForeignPep | null | undefined) => {
    setPersonData(data);

    // If we have successful search results, trigger a history refresh
    if (data && data.match && data.match.length > 0) {
      triggerRefresh();
    }
  };

  // Function to handle report purchase
  const handleReportPurchase = () => {
    // This function should be called when a report is purchased
    triggerRefresh();
  };

  const isDisabled = useMemo(() => {
    return !isSubscriptionValid(subscriptions, isAuthenticated, 22);
  }, [isAuthenticated, subscriptions]);

  return (
    <>
      <div className="KCL_pep-search shell">
        <div className="main__inner-fluid">
          <div className="descriptionWrapper">
            <p className="summary-paragraph">
              Hér geta tilkynningarskyldir aðilar flett upp einstaklingum á móti alþjóðlegum þvingunarlistum, PEP og
              neikvæðri umfjöllun (e. adverse media). Leitað er eftir nafni, fæðingardegi og þjóðerni.
            </p>
            <p className="paragraph">
              Sbr. lög nr. 140/2018 um peningaþvætti og lög nr. 68/2023 um frystingu fjármuna og um framkvæmd
              alþjóðlegra þvingunaraðgerða, ber tilkynningarskyldum aðilum að kanna hvort viðskiptavinir séu á slíkum
              alþjóðlegum válistum.
            </p>
            <p className="paragraph--bold">
              Athugið að hver uppfletting er samkvæmt{" "}
              <Link linkSize="18" url="/Areidanleikakannanir#kynning">
                verðskrá Keldunnar
              </Link>
            </p>
          </div>

          <ForeignPepSearchPersonForm
            isDisabled={isDisabled}
            changeSearchResults={handleSearchResults}
            isAuthenticated={isAuthenticated}
            searchParams={searchParams}
            onReportPurchase={handleReportPurchase} // Pass down the handler
          />
        </div>
        <div>
          {isDisabled && (
            <div style={{ marginTop: "30px" }}>
              <p className="paragraph">
                Ertu tilkynningarskyldur aðili en ekki með aðgang?{" "}
                <Link url="/Hafa-Samband/PEP" linkSize="18">
                  Hafðu samband.
                </Link>
              </p>
            </div>
          )}
          {isAuthenticated && (
            <div style={{ marginTop: "50px", display: "flex", gap: "10px", flexDirection: "column" }}>
              <Link url="/Min-Kelda/Keyptar-Skyrslur/Althjodlegir-Valistar-Einstaklingar" linkSize="16" icon="forward">
                Sjá allar uppflettingar
              </Link>
            </div>
          )}
        </div>
      </div>

      {displayResultSection()}
      <ForeignPepModal show={personShowModal} close={() => setPersonShowModal(false)} data={personModalData} />
    </>
  );
};

export default ForeignPepSearch;
