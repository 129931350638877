// Package imports:
import React, { useEffect, useRef } from "react";
import Bugsnag from "@bugsnag/js";
import * as Yup from "yup";
import { Form, Formik, useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
// Component imports:
import Button from "../../ui-elements/Button/Button";
import Input from "../../ui-elements/Input/Formik/Input";
import Tooltip from "../../ui-elements/Tooltip/Tooltip";
import DropSelectSearch from "../../ui-elements/DropSelect/DropSelectSearch/DropSelectSearch";
import { DatePickerField } from "../../ui-elements/DatePicker/Formik/DatePicker";
// Service imports:
import { useBuyingProcess } from "../../services/buyhook";
import { GET_KELDAN_API_URL } from "../../services/config";
import { ErrorMessages } from "../../services/errorMessages";
// Type imports:
import { IKeldanApiResponse } from "../../types/KeldanTypes";
import { ForeignPep } from "../../types/PepTypes";
//Constants imports:
import { CountryList } from "../../assets/staticData/CountryList";

interface IProps {
  isAuthenticated: boolean;
  changeSearchResults: (data: ForeignPep | null | undefined) => void;
  searchParams: IForeignSearchParams;
  isDisabled: boolean;
  onReportPurchase?: () => void; // Function to call when report is purchased
}
export interface IForeignSearchParams {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
}

interface IFormProps {
  values: ISubFormValues;
  type: "disabled" | "enabled";
}

interface ISubFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string | null;
  countryCode?: string;
}

const ForeignPepSearchPersonForm: React.FC<IProps> = ({
  isDisabled,
  changeSearchResults,
  searchParams,
  onReportPurchase,
}) => {

  const formatDate = (date: string) => {
    //return date in yyyy-mm-dd format
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const { tryPurchaseItem, tryToBuyItem, resetState, ModalsAndToasts } = useBuyingProcess(
    async (values: ISubFormValues, reference: string) => {
      changeSearchResults(null);
      try {
        const requestQuery = new URLSearchParams({
          //required parameters
          firstName: values.firstName,
          lastName: values.lastName,
        });

        //optional parameters
        if (values.middleName) {
          requestQuery.append("middleName", values.middleName);
        }
        if (values.fullName) {
          requestQuery.append("fullName", values.fullName);
        }
        if (values.countryCode) {
          requestQuery.append("nationality", values.countryCode);
        }
        if (values.dateOfBirth) {
          requestQuery.append("dateOfBirth", formatDate(values.dateOfBirth));
        }
        if (reference) {
          requestQuery.append("reference", reference);
        }

        const url = `${GET_KELDAN_API_URL()}/Valistar/Erlend-Skyrsla-Kaupa`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: requestQuery,
        });
        if (!response.ok) {
          resetState({
            headText: ErrorMessages.RequestFailed,
            type: "alert",
          });
        }
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }
        const responseBody: IKeldanApiResponse<ForeignPep> = await response.json();
        if (responseBody.success) {
          if (onReportPurchase) {
            onReportPurchase();
          }
          changeSearchResults(responseBody.result);
          searchResultsRef.current?.scrollIntoView();
          return;
        }
        const { message } = responseBody;
        if (message === "User or customer not subscribed to PEP") {
          resetState({
            headText: ErrorMessages.NoAccess,
            type: "error",
          });
        } else {
          resetState({
            headText: message ?? ErrorMessages.Other,
            type: "error",
          });
        }
      } catch (e) {
        if (e instanceof Error) Bugsnag.notify(e);
        resetState({ headText: ErrorMessages.NetworkError, type: "error" });
      }
    },
    "alþjóðlegum válistum"
  );
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const MyForm: React.FC<IFormProps> = ({ values, type }) => {
    const { resetForm, setFieldValue } = useFormikContext();
    useEffect(() => {
      resetForm({
        values: {
          firstName: searchParams.firstName || "",
          middleName: searchParams.middleName || "",
          lastName: searchParams.lastName || "",
          fullName: "",
          dateOfBirth: searchParams.dob || null,
          countryCode: ""
        },
      });
    }, [searchParams]);
    return (
      <Form>
        <div className="form__body" style={{ marginBottom: "60px" }}>
          <div className="form__section">
            <div className="form__row" style={{ marginBottom: "30px" }}>
              <div className="form__col">
                <Input
                  disabled={type === "disabled"}
                  label="Fornafn"
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                />
              </div>
              <div className="form__col">
                <Input
                  disabled={type === "disabled"}
                  label="Millinafn"
                  name="middleName"
                  id="middleName"
                  value={values.middleName}
                />
              </div>
              <div className="form__col">
                <Input
                  disabled={type === "disabled"}
                  label="Eftirnafn"
                  name="lastName"
                  id="lastName"
                  value={values.lastName}
                />
              </div>
            </div>
            <div className="form__row" style={{ marginBottom: "20px" }}>
              <div className="form__col">
                <h6>
                  Ítarupplýsingar
                  <Tooltip
                    text={<FontAwesomeIcon icon={faInfoCircle} className="icon-fa" />}
                    tooltip={
                      <p>
                        Sé fullt nafn einstaklings í öðru stafrófi en latnesku/rómönsku (til dæmis arabíska eða
                        kínverska) eða ekki hægt að skipta nafni í fornafn, millinafn og eftirnafn er hægt að skrifa
                        inn upprunalegt nafn.
                      </p>
                    }
                    noUnderline
                  />
                </h6>
                <p className="paragraph-small">
                  Með meiri upplýsingum aukast líkur á að einstaklingur finnist en ekki nauðsynlegt að fylla út.
                </p>
              </div>
            </div>
            <div className="form__row">
              <div className="form__col">
                <Input
                  disabled={type === "disabled"}
                  label="Upprunalegt nafn"
                  name="fullName"
                  id="fullName"
                  value={values.fullName}
                />
              </div>
              <div className="form__col datepicker-col">
                <DatePickerField
                  withClearDateButton
                  max_width
                  label="Fæðingardagur"
                  disabled={type === "disabled"}
                  dateFormat="yyyy-MM-dd"
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                />
              </div>
              <div className="form__col">
                <DropSelectSearch
                  disabled={type === "disabled"}
                  size="lg"
                  label="Þjóðerni"
                  options={CountryList}
                  defaultValue={values.countryCode}
                  onChange={(cc: string) => setFieldValue('countryCode', cc)}
                />
              </div>
              <div style={{ flexGrow: 0 }} className="form__col form__actions">
                <Button buttonType="buy" showLoader={tryToBuyItem !== null} disabled={type === "disabled"} size="lg">
                  Kaupa
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  const displayForm = (type: "disabled" | "enabled") => (
    <Formik
      initialValues={{
        firstName: searchParams.firstName || "",
        middleName: searchParams.middleName || "",
        lastName: searchParams.lastName || "",
        fullName: "",
        dateOfBirth: searchParams.dob || null,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Fornafn má ekki vanta"),
        middleName: Yup.string(),
        lastName: Yup.string().required("Eftirnafn má ekki vanta"),
        fullName: Yup.string(),
        nationality: Yup.string(),
        dateOfBirth: Yup.string()
          .nullable()
          .transform((value: string) => {
            if (value) {
              const date = new Date(value);
              const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
                date.getDate()
              ).padStart(2, "0")}`;
              const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(dateString);
              if (!isValidFormat) {
                return new Error("Invalid date format");
              }
              return dateString;
            }
            return null;
          })
          .test("dateOfBirth", "Fæðingardagur verður að vera á forminu YYYY-MM-DD", (value) => {
            if (value) {
              return /^\d{4}-\d{2}-\d{2}$/.test(value);
            }
            return true;
          }),
      })}
      onSubmit={(values) => tryPurchaseItem(values, "confirm-modal-with-reference")}
      component={(props: { values: ISubFormValues }) => <MyForm values={props.values} type={type} />}
    />
  );

  return (
    <>
      {!isDisabled ? (
        displayForm("enabled")
      ) : (
        <Tooltip
          tooltip={<span>Eingöngu fyrir tilkynningarskylda aðila í áskrift.</span>}
          text={displayForm("disabled")}
        />
      )}
      <ModalsAndToasts />
    </>
  );
};

export default ForeignPepSearchPersonForm;
