// Package imports:
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Component imports:
import Input from '../../ui-elements/Input/Formik/Input';
import Button from '../../ui-elements/Button/Button';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { GET_PASSWORD_RESET_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';

interface IFormProps {
    values: IFormValues,
    response?: JSX.Element,
    loading: boolean
}
interface IFormValues {
    email: string,
}


const NewPasswordForm: React.FC = () => {
    const [ response, setResponse ] = useState<JSX.Element>();
    const [loading, setLoading ] = useState(false);

    const handleSubmit = async (values: IFormValues) => {
        setLoading(true)
        try {
            const requestQuery = ({
                username: values.email,
            });
            const url = GET_PASSWORD_RESET_URL();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestQuery)
            });
            
            if (response.ok) {
                setLoading(false)
                const responseBody = await response.text();
            
                if (responseBody.includes('<h1>Error</h1>')) {
                    setResponse(
                        <Alert
                            closeAlert={() => setResponse(<></>)}
                            withCloseIcon
                            type="error"
                            headText='Villa kom upp.'
                            text={<>
                                    <span>Vinsamlegast reynið aftur eða hafið samband við </span>
                                    <a href='mailto:info@keldan.is'>info@keldan.is</a>
                                </>}
                        />
                    );
                } else {
                    setResponse(
                        <Alert 
                            closeAlert={() => setResponse(<></>)}
                            withCloseIcon
                            type="success"
                            headText={'Ef notandi er virkur hefur tölvupóstur verið sendur á ' + values.email}
                        />
                    );
                }
            } else {
                setLoading(false)
                setResponse(
                    <Alert 
                        closeAlert={() => setResponse(<></>)}
                        withCloseIcon
                        type="error"
                        headText={ErrorMessages.RequestFailed}
                    />);
            }
        } catch (e) {
            setLoading(false)
            setResponse(
                <Alert
                    closeAlert={() => setResponse(<></>)}
                    withCloseIcon
                    type="error"
                    headText={ErrorMessages.NetworkError}
                />);
        }
    }

    return (
        <div className={'KCL_new_password-form'}>
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                })}
                onSubmit={handleSubmit}
            >
                {props => <FormComponent {...props} response={response} loading={loading} />}
            </Formik>
        </div>
    );
}

const FormComponent: React.FC<IFormProps> = ({
    values,
    response,
    loading
}) => (
    <Form>
        <div className="form__section">
            <div className="form__body">
                <div className="form__row">
                    <div className="form__col">
                        <Input
                            label="Netfang"
                            name="email"
                            id="email"
                            value={values.email}
                        />
                    </div>
                </div>
            </div>
            {
                response && <div className='contactResponse'>
                    {response}
                </div>
            }
            <div className="form__actions">
                <Button
                    showLoader={loading}
                    type='submit'
                    buttonType="primary"
                    size="lg"
                >
                    Fá sent nýtt lykilorð
                </Button>
            </div>
        </div>
    </Form>
);

export default NewPasswordForm;